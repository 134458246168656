import * as React from 'react';
import {
  Button,
  Center,
  Container,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
} from '@chakra-ui/react';

import useAnalytics from 'src/hooks/analytics';
import { allowNumericInputOnly } from 'src/helpers/utilities';

export const BUTTON_TEXT = 'Find their birthday';
export const CALL_TO_ACTION =
  "We use A.I. to find all your friends' birthdays and make a personal calendar just for you. Try it here!";
export const HEADER_TEXT = 'Be a better friend';
export const INCLUDE_SENDER_NUMBER_TEXT = "Enter your number so we know you're not a bot.";
export const NAME_LABEL = "Friend's Name";
export const NUMBER_LABEL = "Friend's Number";
export const SENDER_NUMBER_LABEL = 'Your Number';
export const SUBHEADER_TEXT = 'Never miss a birthday again with A.I.';

type Props = {
  formDisabled: boolean;
  loading: boolean;
  needSenderNumber: boolean;
  onFieldChange(
    field: string
  ): (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmit(e: React.FormEvent): void;
};

export default function BirthdayFormFields({
  formDisabled,
  loading,
  needSenderNumber,
  onFieldChange,
  onSubmit,
}: Props) {
  const { track } = useAnalytics();

  const trackInput = (field: string) => (_e: React.FocusEvent<HTMLInputElement>) =>
    track(`Enter ${field}`);

  return (
    <>
      <Text
        color="grayTundora"
        fontSize={{ base: '35px', md: '54px' }}
        fontWeight="bold"
        lineHeight={{ base: '39px', md: '62px' }}
        mb="7px"
        mt={{ base: '30px', md: '50px' }}
      >
        {HEADER_TEXT}
      </Text>
      <Text
        color="grayTundora"
        fontSize={{ base: '18px', md: '22px' }}
        fontWeight="semibold"
        lineHeight={{ base: '26px', md: '34px' }}
        mt="8px"
      >
        {SUBHEADER_TEXT}
      </Text>
      <Text color="lightGrayTundora" fontSize="17px" lineHeight="27px" mt="25.5px">
        {CALL_TO_ACTION}
      </Text>
      <Container>
        <form data-cy="birthday-form" onSubmit={onSubmit}>
          <FormLabel color="blueGrey" mt="50px" textAlign="center">
            {NAME_LABEL}
          </FormLabel>
          <Input
            data-cy="name-input"
            onChange={onFieldChange('name')}
            onFocus={trackInput('Name')}
            placeholder="Enter name"
            variant="square"
          />
          <FormLabel color="blueGrey" mt={{ base: '25px', md: '40px' }} textAlign="center">
            {NUMBER_LABEL}
          </FormLabel>
          <NumberInput isValidCharacter={allowNumericInputOnly} variant="square">
            <NumberInputField
              data-cy="phone-number-input"
              onChange={onFieldChange('phoneNumber')}
              onFocus={trackInput('Phone Number')}
              placeholder="1234567890"
            />
          </NumberInput>
          {needSenderNumber && (
            <>
              <Text fontSize="19" lineHeight="27px" mt="52px" textAlign="center">
                {INCLUDE_SENDER_NUMBER_TEXT}
              </Text>
              <FormLabel color="blueGrey" mt="18px" textAlign="center">
                {SENDER_NUMBER_LABEL}
              </FormLabel>
              <NumberInput isValidCharacter={allowNumericInputOnly} variant="square">
                <NumberInputField
                  onChange={onFieldChange('senderNumber')}
                  onFocus={trackInput('Sender Phone Number')}
                  placeholder="1234567890"
                />
              </NumberInput>
            </>
          )}
          <Center h="50" mt={needSenderNumber ? 57 : 57}>
            {loading ? (
              <Spinner color="darkGreyBlueTwo" data-cy="loading-indicator" />
            ) : (
              <Button disabled={formDisabled} type="submit" w="228">
                {BUTTON_TEXT}
              </Button>
            )}
          </Center>
        </form>
      </Container>
    </>
  );
}
