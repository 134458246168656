import { gql, useMutation } from '@apollo/client';

import { ErrorType } from '../utilities';

const FINISH_VERIFICATION_MUTATION = gql`
  mutation FinishVerification($claimId: UUID, $phoneNumber: String!, $verificationCode: String!) {
    finishVerification(
      claimId: $claimId
      phoneNumber: $phoneNumber
      verificationCode: $verificationCode
    ) {
      errors {
        field
        messages
      }
      success
      number {
        number
        birthday
      }
      token
    }
  }
`;

type FinishVerificationDataType = {
  finishVerification: {
    errors: ErrorType[];
    success: boolean;
    number: {
      number: string;
      birthday: string;
    };
    token: string;
  };
};

type FinishVerificationVariablesType = {
  claimId?: string;
  phoneNumber: string;
  verificationCode: string;
};

export function useFinishVerification() {
  return useMutation<FinishVerificationDataType, FinishVerificationVariablesType>(
    FINISH_VERIFICATION_MUTATION
  );
}
