import * as React from 'react';
import { Button, Center, Container, Text } from '@chakra-ui/react';
import Image from 'next/image';

import Confetti from 'public/images/Confetti.gif';
import useAnalytics from 'src/hooks/analytics';

export const BUTTON_TEXT = 'Find another birthday';
export const SUCCESS_MESSAGE = 'We found their birthday!';
let TIMER: NodeJS.Timeout;
let TIMEOUT = 4000;

type Props = {
  birthday: string;
  onClick(): void;
};

export default function BirthdaySuccess({ birthday, onClick }: Props) {
  const [showConfetti, setShowConfetti] = React.useState<boolean>(true);
  const { page } = useAnalytics();

  const stopConfetti = () => {
    TIMER && clearTimeout(TIMER);
    TIMER = setTimeout(() => setShowConfetti(false), TIMEOUT);
  };

  React.useEffect(() => {
    page('Birthday Success');
  }, [page]);

  React.useEffect(() => {
    return () => TIMER && clearTimeout(TIMER);
  });

  return (
    <Center data-cy="birthday-success" flexDir="column" mt="46px">
      {showConfetti ? (
        <Container h="100vh" left="0" pos="absolute" margin="0" maxW="100%" top="0" w="100%">
          <Image
            alt="Burst of confetti"
            data-cy="confetti"
            layout="fill"
            onLoadingComplete={stopConfetti}
            src={Confetti}
          />
        </Container>
      ) : null}
      <Text
        alignSelf="flex-start"
        fontSize={{ base: '19', md: '26' }}
        fontWeight="semibold"
        lineHeight="27px"
      >
        {SUCCESS_MESSAGE}
      </Text>
      <Text alignSelf="flex-start" fontSize={{ base: '40', md: '50' }} fontWeight="bold" mt="20px">
        {birthday}
      </Text>
      <Button mt="32px" onClick={onClick} variant="lightBlue" w="228">
        {BUTTON_TEXT}
      </Button>
    </Center>
  );
}
