import * as React from 'react';
import { Button, Center, Circle, Text } from '@chakra-ui/react';
import { emoji } from 'node-emoji';

import useAnalytics from 'src/hooks/analytics';

export const BUTTON_TEXT = 'Try another birthday';
export const ERROR_MESSAGE = "Uh oh. We couldn't find that one. Try a different friend.";

type Props = {
  onClick(): void;
};

export default function BirthdayError({ onClick }: Props) {
  const { page } = useAnalytics();

  React.useEffect(() => {
    page('Birthday Error');
  }, [page]);

  return (
    <Center data-cy="birthday-error" flexDir="column" mt="24px">
      <Circle backgroundColor="darkGreyBlueTwo10" size="55px">
        <Text fontSize="34px">{emoji.thinking_face}</Text>
      </Circle>
      <Text fontSize="19" fontWeight="semibold" lineHeight="27px" mt="8px" textAlign="center">
        {ERROR_MESSAGE}
      </Text>
      <Button mt="24px" onClick={onClick} variant="lightBlue" w="228px">
        {BUTTON_TEXT}
      </Button>
    </Center>
  );
}
