export type ErrorType = {
  field: string;
  messages: string[];
};

export const allowNumericInputOnly = (value: string) => /\d$/.test(value);

export const displayErrorMessages = (errors: ErrorType[]) => {
  alert(errors.map((error) => error.messages.join(' ')).join(' '));
};

export const formatName = (name: string) => (name.endsWith('s') ? `${name}'` : `${name}'s`);
