import * as React from 'react';
import { Center, Container, Text } from '@chakra-ui/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import { useGuessBirthday } from 'src/helpers/api/guessBirthday';
import { useStartVerification } from 'src/helpers/api/startVerification';
import useAnalytics from 'src/hooks/analytics';
import BirthdayFormFields from 'src/components/BirthdayFormFields';
import BirthdayResult from 'src/components/BirthdayResult';
import Rainbow from 'src/components/Rainbow';
import ResponsiveSplashScreen from 'src/components/ResponsiveSplashScreen';
import SiteHeader from 'src/components/SiteHeader';
import VerificationCode from 'src/components/VerificationCode';

const INITIAL_FORM = {
  name: '',
  phoneNumber: '',
  senderNumber: '',
};
const INVALID_FINGERPRINT_ERROR_MESSAGE = 'Not authorized';
const RETRY_COUNT_KEY = 'birthdaysGuessed';
const RETRY_LIMIT = 2;

export default function FindBirthdayForm() {
  const [fingerprint, setFingerprint] = React.useState<string>('');
  const [form, setForm] = React.useState(INITIAL_FORM);
  const [needSenderNumber, setNeedSenderNumber] = React.useState<boolean>(false);
  const [showBirthdayResult, setShowBirthdayResult] = React.useState<boolean>(false);
  const [showVerification, setShowVerification] = React.useState<boolean>(false);
  const [waitForSecondGuess, setWaitForSecondGuess] = React.useState<boolean>(false);
  const { page, track } = useAnalytics();
  const [guessBirthday, { data, loading }] = useGuessBirthday();
  const [birthdaysRetrieved, setBirthdaysRetrieved] = useLocalStorage(RETRY_COUNT_KEY, 0);
  const { push } = useRouter();
  const [startVerification, { data: startVerificationData }] = useStartVerification();

  const handleGuessBirthday = React.useCallback(() => {
    const { name, phoneNumber } = form;

    guessBirthday({ variables: { fingerprint, name, phoneNumber } });
    track('Find Birthday', { fingerprint, name, phoneNumber });
  }, [fingerprint, form, guessBirthday, track]);

  const handleInput =
    (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setForm({ ...form, [field]: e.target.value });

  const handleSecondBirthdayGuess = React.useCallback(() => {
    setWaitForSecondGuess(true);

    setTimeout(() => {
      handleGuessBirthday();
      setWaitForSecondGuess(false);
    }, 3000);
  }, [handleGuessBirthday]);

  const isFormComplete =
    form.name.length &&
    form.phoneNumber.length === 10 &&
    (!needSenderNumber || form.senderNumber.length === 10);

  const resetForm = () => {
    setForm(INITIAL_FORM);
    setShowBirthdayResult(false);
  };

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    track('Submit Find Birthday Form');
    const { senderNumber } = form;

    if (birthdaysRetrieved === 1) {
      startVerification({ variables: { phoneNumber: senderNumber } });
      setShowVerification(true);
    } else {
      handleGuessBirthday();
    }
  };

  React.useEffect(() => {
    (async () => {
      const fingerprintPromise = FingerprintJS.load();
      const fingerprint = await fingerprintPromise;
      const result = await fingerprint.get();
      setFingerprint(result.visitorId);
    })();
  }, []);

  React.useEffect(() => {
    page('Webview Find Birthday Form');
  }, [page]);

  React.useEffect(() => {
    if (birthdaysRetrieved === 1) {
      setNeedSenderNumber(true);
    } else if (birthdaysRetrieved >= RETRY_LIMIT && !showBirthdayResult) {
      track('Reached Birthday Guess Limit');
      push('/download');
    }
  }, [birthdaysRetrieved, push, showBirthdayResult, track]);

  React.useEffect(() => {
    if (data?.guessBirthday) {
      const { errors, success } = data.guessBirthday;

      if (success) {
        setBirthdaysRetrieved((prev) => prev + 1);
        track('Birthday Found');
        setShowBirthdayResult(true);
      } else if (errors) {
        const messages = errors.map((error) => error.messages.join(' ')).join(' ');

        if (messages.includes(INVALID_FINGERPRINT_ERROR_MESSAGE)) {
          track('Reached Birthday Guess Limit');
          push('/download');
        } else {
          track('Birthday Not Found', { error: messages });
          setShowBirthdayResult(true);
        }
      }
    }
  }, [data, setBirthdaysRetrieved, push, track]);

  React.useEffect(() => {
    if (startVerificationData?.startVerification) {
      setShowVerification(true);
    }

    if (showBirthdayResult) {
      setShowVerification(false);
    }
  }, [showBirthdayResult, startVerificationData]);

  return (
    <>
      <Rainbow />
      <Container
        bgGradient="linear-gradient(to-b, white, creamFrosting)"
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
        maxW="100vw"
        p="0"
      >
        <Container
          maxW={{ md: '50vw' }}
          pl={{ base: '32px', md: '90px' }}
          pr="32px"
          pt={{ base: '60px', md: '55px' }}
        >
          <SiteHeader />
          {showVerification ? (
            <VerificationCode
              buttonText="Find their birthday"
              fingerprint={fingerprint}
              loading={waitForSecondGuess || loading}
              onVerificationSuccess={handleSecondBirthdayGuess}
              phoneNumber={form.senderNumber}
              setShowVerification={setShowVerification}
            />
          ) : showBirthdayResult ? (
            <BirthdayResult birthday={data?.guessBirthday.birthday} onShowForm={resetForm} />
          ) : (
            <BirthdayFormFields
              formDisabled={!isFormComplete || loading}
              loading={loading}
              needSenderNumber={needSenderNumber}
              onFieldChange={handleInput}
              onSubmit={submitForm}
            />
          )}
        </Container>
        <ResponsiveSplashScreen />
      </Container>
      <Center>
        <Text>{new Date().getFullYear()} Time Capsule, Inc. All Rights Reserved.</Text>
      </Center>
    </>
  );
}
