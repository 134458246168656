import * as React from 'react';

import BirthdayError from './BirthdayError';
import BirthdaySuccess from './BirthdaySuccess';

type Props = {
  birthday: string | undefined;
  onShowForm(): void;
};

export default function BirthdayResult({ birthday, onShowForm }: Props) {
  return birthday ? (
    <BirthdaySuccess birthday={birthday} onClick={onShowForm} />
  ) : (
    <BirthdayError onClick={onShowForm} />
  );
}
