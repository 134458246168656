import * as React from 'react';
import { Button, Center, Flex, PinInput, PinInputField, Spinner } from '@chakra-ui/react';
import { useLocalStorage } from 'usehooks-ts';

import { useStartVerification } from 'src/helpers/api/startVerification';
import { useFinishVerification } from 'src/helpers/api/finishVerification';
import { displayErrorMessages } from 'src/helpers/utilities';
import useAnalytics from 'src/hooks/analytics';

type Props = {
  buttonText: string;
  claimId?: string;
  fingerprint?: string;
  loading?: boolean;
  onFinishVerificationCode?(): void;
  onVerificationSuccess(): void;
  phoneNumber: string;
  setShowVerification(state: boolean): void;
};

let TIMEOUT: NodeJS.Timeout;

export default function VerificationCode({
  buttonText,
  claimId = '',
  fingerprint = '',
  loading,
  onFinishVerificationCode,
  onVerificationSuccess,
  phoneNumber,
  setShowVerification,
}: Props) {
  const [code, setCode] = React.useState(['', '', '', '']);
  const [resendText, setResendText] = React.useState<string>("Didn't receive a code?");
  const inputs = [
    React.useRef<HTMLInputElement | null>(null),
    React.useRef<HTMLInputElement | null>(null),
    React.useRef<HTMLInputElement | null>(null),
    React.useRef<HTMLInputElement | null>(null),
  ];
  const [, setToken] = useLocalStorage<string | null>('token', null);
  const [startVerification, { loading: startVerificationLoading }] = useStartVerification();
  const [finishVerification, { data: finishVerificationData, loading: finishVerificationLoading }] =
    useFinishVerification();
  const { identify, page, track } = useAnalytics();

  const handleCode = (char: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let newCode = [...code];
    const value = e.target.value;

    if (value.length > 1) {
      newCode = [...value.split(''), '', '', '', ''].slice(0, 4);
    } else {
      newCode[char] = value;
    }

    setCode(newCode);

    if (value.length === 1 && char < 3) {
      const { current } = inputs[char + 1];
      if (current) {
        current.focus();
      }
    }
  };

  const handlePhoneNumber = () => {
    track('Edits Webview Phone Number');
    setShowVerification(false);
  };

  const handleResendCode = () => {
    setCode(['', '', '', '']);
    track('Resends Webview Verification Code');
    startVerification({
      variables: { phoneNumber },
    });
    setResendText('Code sent!');
    TIMEOUT = setTimeout(() => setResendText("Didn't receive a code?"), 4000);
  };

  const handleVerificationCode = (e: React.FormEvent) => {
    e.preventDefault();
    track('Submit Webview Verification Code');

    if (onFinishVerificationCode) {
      onFinishVerificationCode();
    }

    finishVerification({
      variables: {
        ...(claimId && { claimId }),
        phoneNumber,
        verificationCode: code.join(''),
      },
    });
  };

  React.useEffect(() => {
    if (finishVerificationData && finishVerificationData.finishVerification) {
      const { errors, success, token } = finishVerificationData.finishVerification;

      if (success) {
        identify(phoneNumber, { fingerprint, phoneNumber });
        setToken(token);
        onVerificationSuccess();
      } else {
        displayErrorMessages(errors);
      }
    }
  }, [fingerprint, finishVerificationData, identify, phoneNumber, onVerificationSuccess, setToken]);

  React.useEffect(() => {
    page('Webview Verification Code');

    return () => TIMEOUT && clearTimeout(TIMEOUT);
  }, [page]);

  return (
    <form onSubmit={handleVerificationCode}>
      <Flex justify="space-between" mx="auto" mt="127px" w="284px">
        <PinInput placeholder="">
          <PinInputField
            autoFocus
            data-cy="pin"
            onChange={handleCode(0)}
            ref={inputs[0]}
            value={code[0] || ''}
          />
          <PinInputField
            data-cy="pin"
            onChange={handleCode(1)}
            ref={inputs[1]}
            value={code[1] || ''}
          />
          <PinInputField
            data-cy="pin"
            onChange={handleCode(2)}
            ref={inputs[2]}
            value={code[2] || ''}
          />
          <PinInputField
            data-cy="pin"
            onChange={handleCode(3)}
            ref={inputs[3]}
            value={code[3] || ''}
          />
        </PinInput>
      </Flex>
      <Button data-cy="resend-code" mt="12px" onClick={handleResendCode} variant="ghost">
        {resendText}
      </Button>
      <Button data-cy="back" onClick={handlePhoneNumber} variant="ghost">
        Entered the wrong phone number?
      </Button>
      <Center h="50">
        {loading ? (
          <Spinner color="darkGreyBlueTwo" />
        ) : (
          <Button
            data-cy="submit"
            isDisabled={
              code.some((char) => char === '') ||
              startVerificationLoading ||
              finishVerificationLoading
            }
            mt="64px"
            type="submit"
            w="auto"
          >
            {buttonText}
          </Button>
        )}
      </Center>
    </form>
  );
}
