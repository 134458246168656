import { gql, useMutation } from '@apollo/client';

import { ErrorType } from '../utilities';

const START_VERIFICATION_MUTATION = gql`
  mutation StartVerification($phoneNumber: String!) {
    startVerification(phoneNumber: $phoneNumber) {
      errors {
        field
        messages
      }
      success
    }
  }
`;

type StartVerificationDataType = {
  startVerification: {
    errors: ErrorType[];
    success: boolean;
  };
};

type StartVerificationVariablesType = {
  phoneNumber: string;
};

export function useStartVerification() {
  return useMutation<StartVerificationDataType, StartVerificationVariablesType>(
    START_VERIFICATION_MUTATION
  );
}
