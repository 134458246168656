import { gql, useMutation } from '@apollo/client';

import { ErrorType } from '../utilities';

export const GUESS_BIRTHDAY_MUTATION = `
mutation GuessBirthday($fingerprint: String, $name: String!, $phoneNumber: String!) {
  guessBirthday(fingerprint: $fingerprint, name: $name, phoneNumber: $phoneNumber) {
      birthday
      errors {
          field
          messages
      }
      success
  }
}
`;

const GUESS_BIRTHDAY = gql(GUESS_BIRTHDAY_MUTATION);

type GuessBirthdayDataType = {
  guessBirthday: {
    birthday: string;
    errors: ErrorType[];
    success: boolean;
  };
};

type GuessBirthdayVariablesType = {
  fingerprint?: string;
  name: string;
  phoneNumber: string;
};

export function useGuessBirthday() {
  return useMutation<GuessBirthdayDataType, GuessBirthdayVariablesType>(GUESS_BIRTHDAY);
}
